.content {
	padding: $spacing*2 0;
	.row {
		//align-items: center;
	}
	.image-holder {
		text-align: right;
		img {
			max-width: 100%;
			margin: 0;
		}
	}
	.subtitle {
		font-weight: bold;
		font-size: 14px;
		color: #80b7ca;
		text-transform: uppercase;
	}
	h3 {
		font-weight: 800;
		font-size: 42px;
		line-height: 56px;
		text-align: left;
		color: #000;
		margin: 10px 0 20px 0;
		max-width: 570px;
	}
	.btn {
		background-color: $color1;
		padding: 15px 40px;
		display: inline-block;
		font-weight: normal;
		font-size: 14px;
		color: #fff;
		transition: all 0.3s;
		margin-top: 10px;
		&:hover {
			background-color: $color3;
		}
	}
	&.invert {
		background-color: $color2;
		@media (min-width: $mobile) {
			.col-6:first-of-type {
				order: 2;
			}	
		}
		.image-holder {
			text-align: left;
		}
	}
}

.reviews {
	background-image: url(../images/mountains.jpeg);
	background-size: cover;
	background-position: center;
	color: #fff;
	position: relative;
	.container {
		z-index: 9;
		position: relative;
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #75BBCF;
		z-index: 1;
		opacity: 1;
		mix-blend-mode: multiply;
	}
	.subtitle {
		color: #fff;
	}
	h3 {
		color: #fff;
	}
	
	.reviews-slider {
		.review {
			background: #fff;
			box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.16);
			padding: $spacing;
			.review-author {
				display: flex;
				align-items: center;
				.review-author-image {
					img {
						height: 60px;
						width: 60px;
						display: inline-block;
					}
				}
				.review-author-name {
					margin-left: 10px;
					h4 {
						font-weight: 600;
						font-size: 18px;
						text-align: left;
						color: #000;
						margin: 0;
					}
					p {
						font-weight: 600;
						font-size: 12px;
						text-align: left;
						color: #000;
						opacity: 0.5;
						margin: 0;
					}
				}
			}
			p {
				font-weight: 500;
				font-size: 14px;
				text-align: left;
				color: #000;
				line-height: 21px;
				margin: 10px 0 0 0;
			}
		}
	}
}

.features-main {
	z-index: 99;
  position: relative;
  margin-top: -100px;
	padding-top: 0px !important;
}

.btn {
	background-color: $color1;
	padding: 15px 40px;
	display: inline-block;
	font-weight: normal;
	font-size: 14px;
	color: #fff;	
	transition: all 0.3s;
	margin-top: 10px;
	&:hover {
		background-color: $color3;
	}
}

.greenhosting {
	.header {
		&:before {
			background-color: #A8CF46;
		}
	}
	.content .subtitle {
		color: #8EB941;
	}
	.content.invert {
		background-color: #d7e7bb !important;
	}
}

.green-hosting {
	background-image: url(../images/mountains.jpeg);
	background-size: cover;
	background-position: center;
	color: #fff;
	position: relative;
	.container {
		z-index: 9;
		position: relative;
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #A8CF46;
		z-index: 1;
		opacity: 1;
		mix-blend-mode: multiply;
	}
	.subtitle, p {
		color: #fff !important;
	}
	h3 {
		color: #fff;
	}
	.btn {
		background-color: #4A7F32;
		&:hover {
			background-color: #8EB941;
		}
	}
}


.box{ 
	width: 400px; 
	height: 200px; 
	border: 2px black solid; 
} 

.green-hosting-small {
	background-color: #8EB941;
	color: #fff;
	position: relative;
	overflow: hidden;
	.container {
		z-index: 9;
		position: relative;
	}
	h3 {
		color: #fff;
		max-width: 100%;
		font-size: 36px;
		margin: 0;
	}
	&:hover {
		.yeti-mascot {
			top: -35px;
			transform: rotate(-25deg);
		}
	}
	.yeti-mascot {
		position: absolute;
		top: -15px;
		right: 0;
		height: 220px;
		-webkit-transform: rotate(-30deg);
		transform: rotate(-30deg);
		-webkit-transition: all .3s;
		transition: all .3s;
		@media (max-width: $mobile) {
			display: none;
		} 
	}
}
