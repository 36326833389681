.nav {
	padding: $spacing 0;
	z-index: 99;
	position: relative;
	.container {
		position: relative;
	}
	img {
		height: 75px;
		@media (max-width: $mobile) {
			height: 55px;
		}
	}
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translate(0%, -50%);
		li {
			display: inline-block;
			padding: 0 $spacing/2;
			&:last-child(){
				padding-right: 0;
			}
			&.burger {
				display: none;
			}
			@media (max-width: 915px) {
				display: none;
				&.burger {
					display: block;
					i {
						font-size: 30px;
						margin-right: 10px;
					}
				}
			}
			a {
				font-weight: 500;
				font-size: 14px;
				text-align: center;
				color: #fff;
				text-transform: uppercase;
			}
		}
	}
}

// SASS Variables
$brand: #333333;

// Slide Out Menu Styles
$MENU_WIDTH: 500px;
$MENU_INDENT: 40px;
$SUBMENU_WIDTH: ($MENU_WIDTH - $MENU_INDENT );

.slide-nav {
	width: $MENU_WIDTH;
	max-width: 80%;
	height: 100%;
	position: fixed;
	top: 0;
	left: -$MENU_WIDTH;
	z-index: 100;
	overflow: scroll;
	background-color: $color1;
	transition: ease-in-out 0.3s;
	color: #fff;
	font-size: 18px;
	padding: 45px 20px 20px 20px;
	margin: 0px;
	list-style-type: none;
	z-index: 99999;
	@media (max-width: $mobile) {
		width: 100%;
		max-width: 100%;
		left: -100%;
	}
	li {
		font-family: $primary-font;
		text-transform: uppercase;
		&:first-child {
			border-top: 1px solid #fff;
		}
		border-bottom: 1px solid #fff;
		padding: 8px 0;
	}
	a {
		font-family: $primary-font;
		color: inherit;
		font-size: 18px;
		text-transform: uppercase;
	}
}

.nav-article {
	border: 1px solid #ccc;
	text-align: center;
	img {
		width: 100%;
	}
	.nav-article-title {
		padding: 10px;
	}
}

.pushNavIsOpen {
		overflow: hidden;
		height: 100%;
}

.pushNav_indent * .pushNav {
		background-color: $color1;
		width: $SUBMENU_WIDTH;
}

.top-slide-nav.isOpen,
.slide-nav-level.isOpen {
		left: 0;
}

.close-level, .open-level {
		cursor: pointer;
}

.nav-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	// background-color: rgba(#000, 0.5);
	pointer-events: none;
	opacity: 0;
	transition: all 0.3s;
	z-index: 9999;
}

.nav-overlay.isOpen {
	opacity: 1;
	pointer-events: auto;
}

.slide-nav-level {
	background-color: $color1;
	padding: 55px 20px 20px 20px;
	li:nth-child(2) {
		border-top: 1px solid $color2;
	}
	// box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.75);
	*, a {
		color: #fff;
	}
	&-label {
		color: #fff;
		font-size: 18px;
		position: absolute;
		top: 9px;
		padding: 0px;
		border: none !important;
	}
}

.open-level {
	position: relative;
	&:after{
		content: '';
		position: absolute;
		right: 0;
		top: 50%;
		transform:  translate(0%, -50%) rotate(180deg);
		background-image: url('../images/icons/angle-left.svg');
		background-repeat: no-repeat;
		height: 32px;
		width: 14px;
	}
}

.open-level,
.close-level,
.slide-nav a {
	padding: 12px 0;
	display: block;
	b {
		font-size: 20px;
	}
}


.close-level {
	position: absolute;
	top: 12px;
	right: 20px;
	padding: 0px;
	img {
		height: 26px;
	}
}

.back-level {
	top: 18px;
	color: #fff;
	border: none !important;
	&:after {
		content: '';
		position: absolute;
		top: 45%;
		transform: translate(0%, -50%);
		background-image: url('../images/icons/angle-left.svg');
		background-repeat: no-repeat;
		height: 40px;
		width: 40px;
		left: -20px;
	}
}

a {
	font-size: 16px;
}



.pushNavIsOpen .screen {
	height: 100%;
	opacity: 1;
}


.slide-nav-level-label {
	a {
		padding: 0 !important;
	}
}