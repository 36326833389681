@font-face {
  font-family: 'Font-name';
  font-weight: 700;
  src: url('../fonts/font-name.woff') format('woff');
}

html {
	font-family: $primary-font;
}
h1,h2,h3,h4,h5,h6 {
	font-family: $primary-font;
}
h1,.h1 { font-size: 32px; }
h2,.h2 { font-size: 28px; }
h3,.h3 { font-size: 24px; }
h4,.h4 { font-size: 20px; }
h5,.h5 { font-size: 18px; }
h6,.h6 { font-size: 16px; }

a, button {
	font-family: $secondary-font;
	text-decoration: none;
  color: inherit;
}
input {
	font-family: $secondary-font;
}


p {
	b {
		font-weight: 500;
		font-size: 16px;
		line-height: 26px;
		color: #000;
	}
	font-family: Poppins;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	color: #000;
}

ul {
	padding-left: 17px;
	li {
		b {
			font-weight: 500;
			font-size: 16px;
			line-height: 26px;
			color: #000;
		}
		font-family: Poppins;
		font-weight: normal;
		font-size: 14px;
		line-height: 21px;
		color: #000;
	}
}