.membership-cards {
	z-index: 99;
	position: relative;
	margin-top: -100px;
	.row {
		.col-3 {
			@media (max-width: 915px) {
				width: calc(6/12 * 100%);
			}
			@media (max-width: $mobile) {
				width: 100%;
			}
		}
	}
	.membership-cards-card {
		display: flex;
		flex-direction: column;
		text-align: left;
		padding: $spacing;
		background: #fff;
		box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.16);
		transition: all 0.3s;
		height: 100%;
		&.best-value {
			position: relative;
			&:before {
				content: 'Our Best Value';
				position: absolute;
				top: 0;
				right: 0;
				font-weight: 500;
				font-size: 11px;
				color: #365277;	
				background-color: rgba($color3, 0.4);
				padding: 4px 6px;
				border-radius: 0 0 0 10px;
			}
		}
		&.yeti {
			position: relative;
			&:before {
				content: 'Yeti Recommends';
				position: absolute;
				top: 0;
				right: 0;
				font-weight: 500;
				font-size: 11px;
				color: #365277;	
				background-color: rgba($color3, 0.4);
				padding: 4px 6px;
				border-radius: 0 0 0 10px;
			}
			&:after {
				z-index: -1;
				content: '';
				position: absolute;
				top: -70px;
				right: 5px;
				height: 100px;
				width: 100px;
				background-size: cover;
				background-image: url('../images/yeti.svg');
				@media (max-width: 915px) {
					display: none;
				}
			}
		}
		&.bespoke {
			.btn {
				background-color: $color1;
				color: #fff;
			}			
		}
		&:hover {
			@media (min-width: $mobile) {
				margin-top: -10px;
				margin-bottom: 10px;
				box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.2);	
				.btn {
					background-color: $color3;
					color: #fff;
					border: 2px solid $color3;
				}
			}
		}
		h3 {
			font-weight: 600;
			font-size: 20px;
			text-align: left;
			color: #000;
			margin: 0 0 10px 0;
		}
		p {
			font-weight: 500;
			font-size: 14px;
			text-align: left;
			color: #b1b1b1;
			margin: 10px 0;

			&:last-of-type {
				flex-grow: 1;
			}
		}
		.offer {
			background: #f7f7f7;
	    display: block;
	    color: #28dd68;
	    margin-top: 0;
	    margin-left: -20px;
	    margin-right: -20px;
	    margin-bottom: 10px;
	    padding: 20px;
	    text-align: center;
	    font-weight: 700;

	    span {
	    	display: block;
		    font-size: 32px;
	    }
		}
		.price {
			text-align: left;
			color: #365277;
			font-size: 14px;

	    span {
	    	font-weight: 700;
				font-size: 24px;
	    }
		}
		.spec {
			font-weight: 500;
			font-size: 14px;
			text-align: left;
			color: #b1b1b1;

			background: #f7f7f7;
	    display: block;
	    margin-top: 0;
	    margin-left: -20px;
	    margin-right: -20px;
	    margin-bottom: 10px;
	    padding: 20px;

	    span {
	    	font-weight: 700;
				color: #365277;
				font-size: 16px;
	    }
		}
		.btn {
			text-align: center;
			display: block;
			border: 2px solid $color1;
			padding: 15px;
			font-weight: normal;
			font-size: 14px;
			color: #365277;
			background-color: #fff;
			margin-top: 20px;
			transition: all 0.3s;
		}
	}
}
.levels {
	padding: $spacing*2 0;
}

.statistics {
	padding: $spacing*2 0;
	background-color: #365277;
	.parent-col {
		padding: 0 10px;
	}
	.statistic {
		background-color: #193C60;
		padding: $spacing;
		text-align: center;
		i {
			font-size: 50px;
			color: $color3;
			margin-top: 10px;
		}
		h4 {
			font-weight: 600;
			font-size: 20px;
			margin: 10px 0;
			color: #fff;
		}
		p {
			font-weight: 500;
			font-size: 14px;
			margin: 10px 0;
			line-height: 21px;
			color: #fff;
			&:last-child {
				margin: 0;
			}
		}
		&.large {
			height: 100%;
			padding: $spacing*2;
			.stat-subtitle {
				font-weight: bold;
				font-size: 14px;
				text-align: left;
				color: $color3;
				text-transform: uppercase;
			}
			h4 {
				font-size: 42px;
				font-weight: 800;
				line-height: 56px;
				text-align: left;
				color: #fff;
			}
			p {
				margin: 10px 0;
				b {
					font-weight: 500;
					font-size: 16px;
					color: #fff;
				}
				font-weight: normal;
				font-size: 14px;
				text-align: left;
				color: #fff;
				&:last-child {
					margin: 0;
				}
			}
		}
	}
}
