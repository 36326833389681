// Font
$primary-font: 'Poppins', sans-serif;
$secondary-font: 'Poppins', sans-serif;

// Colours
$color1: #365277;
$color2: #F2F7FB;
$color3: #75bbcf;

// Spacing
$col-spacing: 10px;
$spacing: 20px;

// Screen Sizes
$mobile: 768px;
$small-screen: 992px;
$large-screen: 1200px;

// Cookie
$cookie-background: #000;
$cookie-font-color: #fff;
$cookie-button: #2BA3DB;