.form {
	padding: $spacing*2 0;
	.col-8 {
		.form-group {
			height: 100%;
		}
	}
	.form-group {
		input, textarea {
			background-color: #F8F8F8;
			border: 2px solid #E8E8E8;
			font-size: 16px;
			padding: 10px;
		}
		textarea {
			font-family: $primary-font;
			resize: none;
			width: 100%;
			height: 100%;
		}
		button {
			font-size: 16px;
			padding: 10px;
			background-color: $color1;
			border: 2px solid $color1;
			cursor: pointer;
			color: #fff;
			text-align: center;
			width: 100%;
			transition: all 0.3s;
			&:hover {
				background-color: $color3;
				border: 2px solid $color3;
			}
		}
	}
}

.contact-details {
	padding: 0 0 $spacing*2 0;
	.contact-details-card {
		text-align: center;
		padding: 0 10px;
		.fa-stack {
			font-size: 25px;
			.fa-circle {
				color: $color1;
			}
		}
		h4 {
			font-weight: 600;
			font-size: 18px;
			text-align: center;
			color: #000;
			margin: 10px 0 0 0;
		}
		ul {
			margin: 10px 0 0 0;
			padding: 0;
			list-style-type: none;
			li {
				font-weight: 500;
				font-size: 14px;
				color: #b1b1b1;
				margin-bottom: 5px;
				&:last-child {
					margin: 0;
				}
			}
		}
	}
}