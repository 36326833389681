table {
	width: 100%;
	border-collapse: collapse;
	th {
		width: 13.75%;
		text-align: center;
		font-weight: 600;
		font-size: 20px;
		color: #000;
	}
	th.feature {
		width: 45%;
	}
	tr {
		border: none;
	}
	th, td {
		padding: 15px;
	}
}
tbody {
	tr {
		td {
			text-align: center;
			&.included {
				i {
					color: #28DD68;
				}
			}
			i {
				font-size: 22px;
				color: #C7C7C7;
			}
			&:first-child {
				font-weight: 600;
				font-size: 16px;
				text-align: left;
				color: #999;
			}
		}
		&:nth-child(odd) {
			background-color: #F5F5F5;
		}
	}	
}
