.footer {
	background-color: $color3;
	color: #fff;
	padding: $spacing*2 0;
	h4 {
		font-weight: bold;
		font-size: 24px;
		text-align: left;
		color: #fff;
		margin: 0 0 $spacing 0;
	}
	p {
		font-weight: 500;
		font-size: 14px;
		text-align: left;
		color: #fff;
		max-width: 300px;
		margin: 0;
		line-height: 21px;
	}
	.social {
		margin: $spacing 0 $spacing*3 0;
		padding: 0;
		list-style-type: none;
		li {
			display: inline-block;
			span {
				font-size: 22px;
			}
		}
	}
	.usefull {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;
		li {
			display: inline-block;
			flex-basis: 50%;
			font-weight: 500;
			font-size: 14px;
			text-align: left;
			color: #365277;
			line-height: 21px;
			margin: 0 0 5px 0;
			span {
				font-size: 22px;
			}
		}
	}
	.contact {
		margin: 0;
		padding: 0;
		list-style-type: none;
		li {
			display: inline-block;
			font-weight: 500;
			font-size: 14px;
			text-align: left;
			color: #fff;
			margin: 0 0 5px 0;
			i {
				margin-right: 5px;
				color: $color1;
			}
		}
	}
}

.grecaptcha-badge { 
	visibility: hidden; 
}