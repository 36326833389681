.form-group {
	width: 100%;
	margin: 0px 0px $spacing 0px;
	label, input {
		margin: 0px 0px $spacing 0px;
		display: block;
		width: 100%;
		font-size: inherit;
		font-family: inherit;
	}
	input[type=submit]{
		width: auto;
	}
}

.parsley-error{
	background-color: #F2DEDE !important;
}
.parsley-errors-list{
	list-style: none;
	padding: 0px !important;
	color: #A9555B;
	margin: 0px 0px 10px 0px !important;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #757575; 
}
::-moz-placeholder { /* Firefox 19+ */
  color: #757575; 
  opacity: 1;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #757575; 
  opacity: 1;
}
:-moz-placeholder { /* Firefox 18- */
  color: #757575; 
  opacity: 1;
}
select{
	color: #757575; 
}

.alert{
	margin: 10px 0px;
	padding: 10px;
	&-success{
		background-color: #DFF0D8;
		color: #5B763D;
	}
	&-error{
		background-color: #F2DEDE;
		color: #A9555B;
	}
}