.header {
	background-image: url(../images/mountains.jpeg);
	background-size: cover;
	background-position: bottom;
	margin-top: -122px;
	padding: 200px 0 0 0;
	position: relative;
	@media (max-width: $mobile) {
		height: auto !important;
		padding: 140px 0 200px 0;
	}
	.container {
		z-index: 99;
		position: relative;
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #75BBCF;
		z-index: 1;
		opacity: 1;
		mix-blend-mode: multiply;
	}
	h1 {
		font-weight: 800;
		font-size: 62px;
		line-height: 73px;
		text-align: left;
		color: #fff;
		margin: 0 0 40px 0;
		@media (max-width: $mobile) {
			font-size: 42px;
			line-height: 53px;
			margin: 0 0 20px 0;
		}
	}
	p {
		font-family: Poppins;
		font-weight: normal;
		font-size: 16px;
		text-align: left;
		color: #fff;
		max-width: 400px;
		margin: 0;
	}
}

.small-header {
	@media (max-width: $mobile) {
		height: auto !important;
		padding: 140px 0 100px 0 !important;
	}
}


.homepage-cards {
	z-index: 99;
	position: relative;
	margin-top: -150px;
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 150px;
		z-index: -1;
		background-color: $color2;
	}
	.row {
		.col-3 {
			@media (max-width: 915px) {
				width: calc(6/12 * 100%);
			}
			@media (max-width: $mobile) {
				width: 100%;
			}
		}
	}
	.homepage-cards-card {
		text-align: center;
		padding: $spacing;
		background: #fff;
		box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.16);
		transition: all 0.3s;
		height: 100%;
		display: flex;
		flex-direction: column;
		&:hover {
			@media (min-width: 915px) {
				margin-top: -10px;
				box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.2);	
			}
		}
		.fa-stack {
			margin: auto;
			font-size: 35px;
			.fa-circle {
				color: #75BBCF;
			}
		}
		h3 {
			font-weight: 600;
			font-size: 18px;
			text-align: center;
			color: #000;
		}
		p {
			font-weight: 500;
			font-size: 14px;
			text-align: center;
			color: #b1b1b1;
			flex-grow: 1;
		}
		span {
			font-weight: 600;
			font-size: 12px;
			color: #000;
		}
	}
}

.domain-search {
	background-color: $color2;
	padding: $spacing*2 0;
	position: relative;
	overflow: hidden;
	&:hover {
		.yeti-mascot {
			top: -20px;
			transform: rotate(25deg);
		}
	}
	.container {
		position: relative;
	}
	.yeti-mascot {
		position: absolute;	
		top: -10px;
		left: 0;
		height: 220px;
		transform: rotate(30deg);
		transition: all 0.3s;
		@media (max-width: $mobile) {
			display: none;
		}
	}
	.domain-search-holder {
		display: flex;
		background-color: #fff;
		padding: $spacing;
		box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
		width: 80%;
		float: right;
		align-items: center;
		@media (max-width: $mobile) {
			flex-wrap: wrap;
		}
		@media (max-width: $mobile) {
			width: 100%;
		}
		.domain-search-content {
			padding-right: 5px;
			flex-basis: 50%;
			@media (max-width: $mobile) {
				flex-basis: 100%;	
				padding: 0 0 10px 0;
			}
			h4 {
				margin: 0;
				font-weight: 600;
				font-size: 18px;
				text-align: left;
				color: #000;
				margin-bottom: 10px;
				@media (max-width: $mobile) {
					text-align: center;
				}
			}
			span {
				font-weight: normal;
				font-size: 16px;
				text-align: left;
				color: #000;
				@media (max-width: $mobile) {
					display: none;
				}
			}
		}
		.domain-search-form {
			padding-left: 5px;
			flex-basis: 55%;
			@media (max-width: $mobile) {
				flex-basis: 100%;	
				padding: 0 0 10px 0;
			}
			form {
				width: 100%;
				display: flex;
				input {
					width: calc(100% - 55px);
					background: #eee;
					border: none;
					font-size: 16px;
					padding: 15px;
					display: inline-block;
				}
				button {
					background-color: $color1;
					border: none;
					color: #fff;
					height: 55px;
					width: 55px;
					display: inline-block;
					cursor: pointer;
					transition: all 0.3s;
					&:hover {
						background-color: $color3;
					}
				}
			}
		}
	}
}

.features {
	.more-link {
		text-align: center;
		display: block;
		font-weight: 600;
		font-size: 14px;
		color: #000;
		margin: 20px 0 0 0;
	}
	a:hover {
		.features-holder {
			@media (min-width: $mobile) {
				box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.2);
				margin-top: -10px;
				margin-bottom: 10px;
			}
			.features-content span {
				background-color: $color3;
			}
		}
	}
	.features-holder {
		background-color: #fff;
		box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.16);
		display: flex;
		transition: all 0.3s;
		position: relative;
		z-index: 99;
		height: 100%;
		.features-icon {
			background-image: url(../images/feature-background.png);
			width: 20%;
			position: relative;
			background-size: cover;
			i {
				font-size: 50px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.features-content {
			padding: $spacing*2 $spacing;
			position: relative;
			width: 80%;
			.feature-title {
				font-weight: bold;
				font-size: 14px;
				text-transform: uppercase;
				color: #75bbcf;
				margin-bottom: 5px;
			}
			h4 {
				font-weight: 600;
				font-size: 18px;
				color: #000;
				line-height: 27px;
				margin: 0 0 10px 0;
			}
			p {
				margin: 0;
				font-weight: 500;
				font-size: 14px;
				color: #3b3b3b;
				line-height: 21px;
				max-width: 80%;
			}
			span {
				background-color: $color1;
				height: 60px;
				width: 60px;
				display: inline-block;
				position: absolute;
				bottom: 0;
				right: 0;
				transition: all 0.3s;
				i {
					color: #fff;
					font-size: 20px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}
}

