.slick-slide {
	margin: 0 10px;
}
/* the parent */
.slick-list {
	margin: 0 -10px;
}
.slick-dots {
	margin: 20px 0;
	padding: 0;
	list-style-type: none;
	text-align: center;
	li {
		display: inline-block;
		margin: 0 5px;
		&.slick-active {
			button {
				background-color: $color1;
			}
		}
		button {
			border: none;
			background-color: #fff;
			height: 15px;
			width: 15px;
			border-radius: 10px;
			font-size: 0;
			color: transparent;
			padding: 0;
			cursor: pointer;
			transition: all 0.3s;
		}
	}
}

.reviews-slider {
	visibility: hidden;
	.slick-track {
		display: flex !important;
	}
	.slick-slide {
		height: inherit !important;
		.review {
			height: 100%;
		}
	}
	
}
.slick-initialized {
	visibility: visible;
}